@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  /* Initially set the body to be fully transparent */
  opacity: 0;
  /* Apply the fadeIn animation over 2 seconds */
  animation: fadeIn 2s forwards;
}

.centerpiece {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent black background for better text visibility */
  position: relative;
}

.centerpiece h1 {
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  color: white;
  margin-bottom: 1rem;
}

.centerpiece p {
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  color: white;
}

.centerpiece::before,
.centerpiece::after {
  content: "";
  position: absolute;
  left: 10%;
  right: 10%;
  height: 2px;
  background: white;
}

.centerpiece::before {
  top: 30%;
}

.centerpiece::after {
  bottom: 30%;
}

.line-with-notch {
  position: absolute;
  top: calc(30% - 116px);
  left: 50%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.line-with-notch i {
  margin-bottom: 10px;
}

.line-with-notch::before {
  content: "";
  width: 2px;
  height: 69px;
  background: white;
}

.main-content-holder {
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  border: 2px solid white;
}

.section {
  flex: 1;
  padding: 20px;
  border-right: 2px solid white;
}

.transparent-button {
  background-color: transparent;
  font-weight: 600;
  color: white;
  padding: 0.5rem 1rem;
  border: 1px solid white;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 1rem;
}

.transparent-button:hover {
  background-color: #ffa500;
  border-color: transparent;
}

.transparent-button:not(:last-child) {
  margin-right: 1rem;
}

.modal {
  max-width: 100%;
  margin: 0 auto;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-50w .modal-dialog {
  max-width: 50%;
}

@media (max-width: 768px) {
  .modal-50w .modal-dialog {
    max-width: 100%;
  }
}
